<template>
  <v-container
    id="data-tables"
    tag="section"
  >
    <base-material-card
      color="indigo"
      icon="mdi-tag-multiple"
      inline
      class="px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Manage Tags
        </div>
      </template>
      <v-row>
        <v-col class="text-sm-center">
          <v-dialog v-model="addTag" :retain-focus="false" max-width="600px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="mt-4 mr-0"
              >
                Add Tag
                <v-icon
                  right
                  dark
                >
                  mdi-tag-plus
                </v-icon>
              </v-btn>
            </template>
            <v-card :loading="cardLoading">
              <v-card-title>
                <span class="headline">Add Tag</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="tagName" label="Tag Name*" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select v-model="tagType" :items="newTagOptions" item-text="text" item-value="value" label="Tag Type*" required></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-on:click.stop="addTag = false" color="red darken-1">Nevermind</v-btn>
                <v-btn :disabled="!tagName || !tagType" color="green darken-1" v-on:click.stop="createTag">Create</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="4" sm="12">
          <v-card>
            <v-card-title>
              Tags
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchTag"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="tags"
              :search="searchTag"
              :loading="tagLoading"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-edit-dialog
                      large
                      persistent
                      @save="saveTag(item)"
                    >
                      <v-icon
                        small
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                      >
                      mdi-tag-text
                      </v-icon>
                      <template v-slot:input>
                        <v-text-field
                          v-model="tagRenameVar"
                          :rules="[max25chars, required]"
                          label="Enter a new tag name"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <span>Rename Tag</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click.stop="deleteTagCheck(item)"
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-tag-remove
                    </v-icon>
                  </template>
                <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="4" md="4" sm="12">
          <v-card>
            <v-card-title>
              Categories
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchCat"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="categories"
              :search="searchCat"
              :loading="catLoading"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-edit-dialog
                      large
                      persistent
                      @save="saveTag(item)"
                    >
                      <v-icon
                        small
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                      >
                      mdi-tag-text
                      </v-icon>
                      <template v-slot:input>
                        <v-text-field
                          v-model="catRenameVar"
                          :rules="[max25chars, required]"
                          label="Enter a new category name"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <span>Rename category</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click.stop="deleteTagCheck(item)"
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-tag-remove
                    </v-icon>
                  </template>
                <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="4" md="4" sm="12">
          <v-card>
            <v-card-title>
              Super Tags
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchSuper"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="superTags"
              :search="searchSuper"
              :loading="superLoading"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-edit-dialog
                      large
                      persistent
                      @save="saveTag(item)"
                    >
                      <v-icon
                        small
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                      >
                      mdi-tag-text
                      </v-icon>
                      <template v-slot:input>
                        <v-text-field
                          v-model="supRenameVar"
                          :rules="[max25chars, required]"
                          label="Enter a new super tag name"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <span>Rename Super Tag</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click.stop="deleteTagCheck(item)"
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-tag-remove
                    </v-icon>
                  </template>
                <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-dialog
          :retain-focus="false"
          v-model="deleteTagConfirm"
          max-width="500"
        >
          <v-card>
            <v-card-title class="headline">Are you sure?</v-card-title>
            <v-card-text>
              This tag cannot be deleted if it has existing associations. <br> If you wish to delete it and remove it from connected listings/videos, please contact an administrator
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                v-on:click.stop="deleteTagConfirm = false"
              >
                No, don't delete
              </v-btn>
              <v-btn
                color="red darken-1"
                v-on:click.stop="deleteTag"
              >
              Yes, delete tag
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-snackbar
        top right
        v-model="snack"
        :timeout="snackTimeout"
        :color="snackColor"
      >
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            text
            @click="snack = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </base-material-card>
  </v-container>
</template>

<script>
import tagManagement from '@/Services/TagManagement'
export default {
  name: 'TagTable',
  data: () => {
    return {
      searchTag: '',
      searchCat: '',
      searchSuper: '',
      headers: [
        { text: 'Tag Name', align: 'start', sortable: false, value: 'tagName' },
        { text: 'Tag ID', value: 'id' },
        { text: 'Tag Type', value: 'tagType' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      tags: [],
      categories: [],
      superTags: [],
      tagLoading: false,
      catLoading: false,
      superLoading: false,
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: null,
      required: v => !!v || 'Required',
      max25chars: v => v.length <= 25 || 'Input too long!',
      deleteTagConfirm: false,
      deletedTagID: '',
      deletedTagType: '',
      newTagOptions: [
        { text: 'Please select a tag type', value: null },
        { text: 'Tag', value: 2 },
        { text: 'Category', value: 1 },
        { text: 'Super Tag', value: 10 }
      ],
      tagName: null,
      tagType: null,
      cardLoading: false,
      addTag: false,
      tagRenameVar: '',
      catRenameVar: '',
      supRenameVar: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.tagType = null
      this.tagName = null
      this.searchTag = ''
      this.searchCat = ''
      this.searchSuper = ''
      this.tagLoading = true
      this.catLoading = true
      this.superLoading = true
      await tagManagement.getTags()
        .then((response) => {
          this.$set(this, 'tags', response.data)
          this.tagLoading = false
        })
      await tagManagement.getCats()
        .then((response) => {
          this.$set(this, 'categories', response.data)
          this.catLoading = false
        })
      await tagManagement.getSuperTags()
        .then((response) => {
          this.$set(this, 'superTags', response.data)
          this.superLoading = false
        })
    },
    async saveTag (item) {
      switch (item.tagType) {
        case 1:
          item.tagName = this.catRenameVar
          break
        case 2:
          item.tagName = this.tagRenameVar
          break
        case 10:
          item.tagName = this.supRenameVar
          break
        default:
          // eslint-disable-next-line
          console.warn('Could not identify tag type')
      }
      this.tagLoading = true
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Saving Tag Name...'
      this.snackTimeout = -1
      await tagManagement.renameTag(item.id, item.tagName, item.tagType)
        .then((response) => {
          if (response.status === 200) {
            this.tagLoading = false
            this.init()
            this.snackColor = 'success'
            this.snackText = 'Tag renamed successfully'
            this.snackTimeout = 3000
          } else {
            this.tagLoading = false
            this.snackColor = 'error'
            this.snackText = 'Failed to rename tag. ' + response.data.message
            this.snackTimeout = 5000
            this.init()
          }
        })
      this.tagRenameVar = ''
      this.catRenameVar = ''
      this.supRenameVar = ''
    },
    deleteTagCheck (item) {
      this.deletedTagID = item.id
      this.deletedTagType = item.tagType
      this.deleteTagConfirm = true
    },
    async deleteTag () {
      this.tagLoading = true
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Deleting Tag...'
      this.snackTimeout = -1
      await tagManagement.deleteTag(this.deletedTagID, this.deletedTagType)
        .then((response) => {
          if (response.status === 200) {
            this.tagLoading = false
            this.deleteTagConfirm = false
            this.init()
            this.snackColor = 'success'
            this.snackText = 'Tag deleted successfully'
            this.snackTimeout = 3000
          } else {
            this.tagLoading = false
            this.deleteTagConfirm = false
            this.snackColor = 'error'
            this.snackText = 'Failed to delete tag. ' + response.data.message
            this.snackTimeout = 5000
          }
        })
    },
    async createTag () {
      this.tagLoading = true
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Adding Tag...'
      this.snackTimeout = -1
      await tagManagement.addTag(this.tagName, this.tagType)
        .then((response) => {
          if (response.status === 200) {
            this.addTag = false
            this.tagLoading = false
            this.init()
            this.snackColor = 'success'
            this.snackText = 'Tag added successfully'
            this.snackTimeout = 3000
          } else {
            this.addTag = false
            this.tagLoading = false
            this.snackColor = 'error'
            this.snackText = 'Failed to create tag. ' + response.data.message
            this.snackTimeout = 5000
          }
        })
    }
  }
}
</script>
