<script>
// import { api } from '@/http-common.js'
import axios from 'axios'
import { getInstance } from '../auth/index'
const authService = getInstance()
export default {
  async getCats () {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/support-type/admin'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getTags () {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/tags/admin'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getSuperTags () {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'articles/supertags/admin'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async addTag (tagName, tagType) {
    let endpoint
    switch (tagType) {
      case 1:
        endpoint = 'articles/support-type'
        break
      case 2:
        endpoint = 'articles/tags'
        break
      case 10:
        endpoint = 'articles/super-tag'
        break
      default:
        // eslint-disable-next-line
        console.error('No tag type found.')
    }
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + endpoint
    const ops = {
      method: 'POST',
      data: {
        tagName: tagName
      },
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async renameTag (id, tagName, tagType) {
    let endpoint
    switch (tagType) {
      case 1:
        endpoint = 'articles/support-type'
        break
      case 2:
        endpoint = 'articles/tags'
        break
      case 10:
        endpoint = 'articles/super-tag'
        break
      default:
        // eslint-disable-next-line
        console.error('No tag type found.')
    }
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + endpoint
    const ops = {
      method: 'PUT',
      data: {
        id: id,
        tagName: tagName
      },
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async deleteTag (id, tagType) {
    let endpoint
    switch (tagType) {
      case 1:
        endpoint = 'articles/support-type'
        break
      case 2:
        endpoint = 'articles/tags'
        break
      case 10:
        endpoint = 'articles/super-tag'
        break
      default:
        // eslint-disable-next-line
        console.error('No tag type found.')
    }
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + endpoint
    const ops = {
      method: 'DELETE',
      data: {
        id: id
      },
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
